




















































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab3Question6',
  components: {TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        aiQuestionResponse: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      flowChart: [] as File[],
      questions: [
        {
          en: 'Describe how you would separate a mixture of benzyl amine (an organic base) and naphthalene. Both compounds are insoluble in water and soluble in ether. This question can be answered using a flow chart which you can upload using the upload feature at the bottom of this task.',
          fr: 'Décrivez comment séparer un mélange d’amine benzylique (base organique) et de naphtalène. Les deux composés sont insolubles dans l’eau, mais solubles dans l’oxyde de diéthyle. Cette question peut être répondue en utilisant un diagramme de flux que vous pouvez télécharger en utilisant la fonction de téléchargement en bas de cette tâche.',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
  computed: {
    attachments(): File[] {
      return [...this.flowChart];
    },
  },
});
